<template>
    <div class="products">
      <div class="page-header">
        <h1>{{ $t('Member Management')}} > {{ $t('Member Level') }}</h1>
      </div>
      <div class="page-content">
        <div class="content-header">
          <h2>{{ $t('Member News') }}</h2>
          <el-button class="action-button" type="primary">{{ $t('Create Member Level') }}</el-button>
        </div>
  
        <div class="inventory-list">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">{{ $t('Level') }}</th>
                <th scope="col">{{ $t('Created Date') }}</th>
                <th scope="col">{{ $t('Current Members') }}</th>
                <th scope="col">{{ $t('Actions') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Bronze</td>
                <td>2024-08-09 15:00:00</td>
                <td>560</td>
                <td>
                 <a class="action-link">
                  {{ $t('Edit') }}
                 </a>
                </td>
              </tr>
              <tr>
                <td>Silver</td>
                <td>2024-07-09 13:57:39</td>
                <td>100</td>
                <td>
                 <a class="action-link">
                  {{ $t('View') }}
                 </a>
                </td>
              </tr>
              <tr>
                <td>Golden</td>
                <td>2024-06-09 12:45:21</td>
                <td>50</td>
                <td>
                 <a class="action-link">
                  {{ $t('View') }}
                 </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapState, mapActions } from 'vuex';
  import ItemFilter from '@/components/Filter.vue';
  import Common from '@/lib/common';
  import Member from '@/lib/member';
  
  export default {
    name: 'Members',
    components: {
      ItemFilter,
    },
    mounted(){
      this.loadAllMembers();
    },
    methods:{
      async loadAllMembers(){
        try{
          const loginInfo = Common.getLoginInfo();
          const members = await Member.loadAllMembers(this.apiUrl, loginInfo);
          this.members = members;
        }catch(err){
          console.log(err);
        }
      },
    },
    data(){
      return {
        members: [],
        orignalMembers: [],
      }
    },
    computed: mapState({
     apiUrl: state => state.apiUrl,
   }),
  }
  </script>
  
  <style scoped lang="scss">
  .content-header{
    display: flex;
    h2{
      flex: 1;
    }
    .action-button{
      margin-right: 20px;
    }
  }
  </style>
  